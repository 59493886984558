import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {UrlTree} from '@angular/router';
import {UserService} from './user.service';
import {JwtService, FwTokenType} from './jwt.service';

@Injectable({
    providedIn: 'root'
})
export class TokenGuard  {

    constructor(
        private userService: UserService,
        private jwtService: JwtService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // if there is no token, get it, else do nothing
        if (!this.jwtService.getToken(FwTokenType.ACCESS_TOKEN)) {
            return this.userService.newAnonymous();
        } else {
            return true;
        }
    }
}
