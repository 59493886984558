import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {UrlTree} from '@angular/router';
import {map} from 'rxjs/operators';
import {JwtService, FwTokenType} from './jwt.service';
import {UserService} from './user.service';
import {SettingsService} from '../../services/settings.service';

@Injectable({
    providedIn: 'root'
})
export class LoggedInGuard  {
    constructor(
        private router: Router,
        private userService: UserService,
        private jwtService: JwtService,
        private settingsService: SettingsService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.jwtService.getToken(FwTokenType.ACCESS_TOKEN)) {
            // if there is no token, get one, then redirect to login
            return this.userService.newAnonymous().pipe(
                map(() => {
                    this.settingsService.frameworkSettings.auth.afterLoginDestination = state.url;

                    this.router.navigate([this.settingsService.frameworkSettings.auth.loginDestination]);
                    return false;
                })
            );
        } else {
            // if there is a token, update userdata
            return this.userService.getUserData().pipe(
                map(() => {
                    // if there is a user continue, else redirect to login
                    if (this.userService.currentUser) {
                        return true;
                    } else {
                        this.settingsService.frameworkSettings.auth.afterLoginDestination = state.url;

                        this.router.navigate([this.settingsService.frameworkSettings.auth.loginDestination]);
                        return false;
                    }
                })
            );
        }
    }
}
