import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {UrlTree} from '@angular/router';
import {SettingsService} from '../../services/settings.service';
import {standaloneConfig} from '../../settings';

@Injectable({
    providedIn: 'root'
})
export class SettingsGuard  {
    constructor(
        private settingsService: SettingsService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        // TODO: settings should be loaded via APP_INITIALIZER, but this is good enough for now
        if (!this.settingsService.frameworkSettings) {
            this.settingsService.set(standaloneConfig);
        }

        return true;
    }
}
