import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppRouting} from './app.routing';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';

import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {registerLocaleData} from '@angular/common';
import localeDE from '@angular/common/locales/de';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {UserService} from './framework/modules/auth/user.service';
import {TokenInterceptor} from './framework/modules/auth/token.interceptor';

import {environment} from '../environments/environment';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {ModalModule} from 'ngx-bootstrap/modal';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import {ErrorsModule} from './errors/errors.module';
import {FrameworkModule} from './framework/framework.module';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {NgxMatomoTrackerModule} from '@ngx-matomo/tracker';
import {NgxMatomoRouterModule} from '@ngx-matomo/router';
import {MultiTranslateHttpLoader} from "ngx-translate-multi-http-loader";

registerLocaleData(localeDE);

export function HttpLoaderFactory(http: HttpBackend): MultiTranslateHttpLoader {
    return new MultiTranslateHttpLoader(http, [
        {prefix: './assets/i18n/', suffix: '.json'},
    ]);
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams): string {
        if (!environment.production) {
            console.log('Missing translation for "' + params.key + '"');
        }
        return params.key;
    }
}

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.domain
    },
    position: 'bottom-left',
    theme: 'classic',
    palette: {
        popup: {
            background: '#000000',
            text: '#ffffff',
            link: '#ffffff'
        },
        button: {
            background: '#f1d600',
            text: '#000000',
            border: 'transparent'
        }
    },
    type: 'info',
    content: {
        message: 'Diese Webseite verwendet Cookies. Genauere Informationen findest du in unserer Datenschutzerklärung.',
        dismiss: 'Ich stimme zu',
        deny: 'Refuse cookies',
        link: 'Mehr erfahren',
        href: 'privacy',
        policy: 'Cookie Policy'
    }
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        NgcCookieConsentModule.forRoot(cookieConfig),
        BrowserModule,
        AppRouting,
        HttpClientModule,
        BrowserAnimationsModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        AccordionModule.forRoot(),
        ToastrModule.forRoot(),
        ErrorsModule,
        TypeaheadModule.forRoot(),
        PaginationModule.forRoot(),
        TranslateModule.forRoot({
            missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend]
            }
        }),
        FrameworkModule,
        NgxMatomoTrackerModule.forRoot({
            disabled: environment.matomoTrackerUrl === '',
            trackerUrl: environment.matomoTrackerUrl,
            siteId: environment.matomoSiteId,
        }),
        NgxMatomoRouterModule
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'de-AT'},
        UserService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
