import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SettingsGuard} from './framework/modules/auth/settings.guard';
import {TokenGuard} from './framework/modules/auth/token.guard';
import {LoggedInGuard} from './framework/modules/auth/loggedIn.guard';
import {RightsLoadedGuard} from './global/guards/rights-loaded.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
        canActivate: [SettingsGuard, TokenGuard],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'app',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        canActivate: [SettingsGuard, LoggedInGuard, RightsLoadedGuard],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'error',
        loadChildren: () => import('./errors/errors.module').then(m => m.ErrorsModule),
        canActivate: [SettingsGuard],
        runGuardsAndResolvers: 'always'
    },
    {
        path: '**',
        loadChildren: () => import('./errors/errors.module').then(m => m.ErrorsModule),
        canActivate: [SettingsGuard],
        runGuardsAndResolvers: 'always'
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload',
        scrollPositionRestoration: 'enabled',
    })],
    exports: [RouterModule],
})
export class AppRouting {
}

