import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {AccessService} from '../services/access.service';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

/**
 * Loads all rights for the data-right attributes
 */
@Injectable({
    providedIn: 'root'
})
export class RightsLoadedGuard  {

    constructor(
        private http: HttpClient,
        private accessService: AccessService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.http
            .post('api/api.php', {
                action: 'Role/listRightPairs',
                data: {},
                componentName: 'rights-loaded.guard.ts',
                methodName: 'canActivate()'
            })
            .pipe(
                map((response: any) => {
                    this.accessService.rights = response.rights;
                    return true;
                })
            );
    }

}
