import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from './framework/modules/auth/user.service';
import {NgcCookieConsentService} from 'ngx-cookieconsent';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {environment} from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    private popupOpen$!: Subscription;
    private userStatus$!: Subscription;
    public environment = environment;

    constructor(
        private translateService: TranslateService,
        public userService: UserService,
        private ccService: NgcCookieConsentService,
    ) {
    }

    ngOnInit(): void {
        //let browserLanguage = this.translateService.getBrowserLang();
        let browserLanguage = 'de'; //browserLanguage?.match(/en|de/) ? browserLanguage : 'en';
        this.translateService.use(browserLanguage);

        this.userStatus$ = this.userService.tokenChanged.subscribe(() => {
            const clientLabels = this.userService.currentUser?.client_labels;

            if (clientLabels) {
                this.translateService.getTranslation('de').pipe(take(1)).subscribe(response => {
                    this.translateService.setTranslation('de', JSON.parse(clientLabels), true);
                });
            }
        });

        this.popupOpen$ = this.ccService.popupOpen$.subscribe(
            () => {
                // you can use this.ccService.getConfig() to do stuff...
            });
    }

    ngOnDestroy(): void {
        this.popupOpen$.unsubscribe();
        this.userStatus$.unsubscribe();
    }

}
